@media all and (min-width: 480px) {
    .login {
      padding: 60px 0;
    }
  
    .login form {
      margin: 0 auto;
      max-width: 320px;
      text-align: center;
    }

    .login form p {
        color: crimson;
    }

    .login h6 {
      color: #b5b5b5;
    }

    .login h3 {
      color: #d3d3d3;
    }

    .LoginForm-label {
      color: #d3d3d3;
    }
  }