.Header-outer {
    display: inline-block;
    vertical-align: top;
    color: #9DA5AB;
}

.Header-inner {
    line-height: 24px;
    vertical-align: middle;
}

.Header-highlight {
    color: #fd7e14;
}

.Header-italic {
    font-style: italic;
}

.Header-modified {
    font-weight: bold;
}