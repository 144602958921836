.NodeViewer-centerize {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.NodeViewer-form {
    font-size: medium;
}

.NodeViewer-textarea {
    background-color: #323842 !important;
    color: rgb(241, 241, 241) !important;
}

.NodeViewer-textarea-light {
  background-color: #515965 !important;
  color: rgb(241, 241, 241) !important;
}

.react-dropdown-select-item {
    background-color: #323842 !important;
}

/* hiding arrows in numerical input fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}