svg {
    vertical-align: top !important;
}

.found {
    color: #b94646;
    font-weight: bold;
}

.big-tree {
    max-height: 58vh;
    overflow: auto;
    background-color: #282c34 !important;
    border: 1px solid black;
    padding: 5px 
}

.helper-tree {
    max-height: 20vh;
    overflow: auto;
    background-color: #282c34 !important;
    border: 1px solid black;
    padding: 5px 
}

.css-f91fgu {
    background-color: #282c34 !important;
}

.CustomInput-input {
    background-color: transparent;
    border: none;
    color: #9DA5AB;
}
